export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyD1jlOsDtfx2XFcSXCVvwAVecgb-DtTWYY',
    authDomain: 'medxat-app-europa.firebaseapp.com',
    databaseURL: 'https://medxat-app-europa.firebaseio.com',
    projectId: 'medxat-app-europa',
    storageBucket: 'medxat-app-europa.appspot.com',
    messagingSenderId: '420207443604'
  },
  API_ENDPOINT: 'https://api.medxat.com/symfony/public/api/egea',
  MEDXAT_API_ENDPOINT: 'https://api.medxat.com/symfony/public/api',
  MEDXAT_TOKEN_ENDPOINT: "https://europe-west1-medxat-app-europa.cloudfunctions.net/users_createToken"
};
