import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Router, NavigationExtras } from '@angular/router';
import { UserService } from './user.service';

const { Browser } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  isIE = false;
  public medxatLink = "https://app.medxat.com/?minerva=true&userEmail=pol.fernandez%2Bhp@atheneasolutions.com&withToken=true";
  constructor(public router: Router,
    public userService: UserService) {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
      this.isIE = true;
      console.log("BROWSER IS IE")
    }
    else {
      this.isIE = false;
    }
  }

  openLink(mode, params?) {
    if (mode === 'ios') {
      Browser.open({ url: "https://apps.apple.com/es/app/medxat/id1283026127" });
    } else if (mode === 'android') {
      Browser.open({ url: "https://play.google.com/store/apps/details?id=com.asolutions.medxat&hl=ca" });
    } else if (mode == 'goto-payment') {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          params: JSON.stringify(params)
        }
      };
      this.router.navigate(['home'], navigationExtras);
    } else {
      Browser.open({ url: `https://app.medxat.com/?minerva=true&userEmail=${encodeURIComponent(this.userService.user.profile.email)}` });
    }
  }
}
