import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ReplaySubject, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CommunicationService } from './communication.service';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: any;
  public id;
  public token: any;
  public medxatToken: any;
  public medxatURL;

  public isAuthenticated$: ReplaySubject<boolean> = new ReplaySubject(1);
  public medxatToken$: ReplaySubject<boolean> = new ReplaySubject(1);
  public onSignIn$: Subject<boolean> = new Subject();
  public onSignOut$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    public comm: CommunicationService,
    public router: Router) {

    this.auth.user
      .subscribe(async (user) => {
        if (user) {
          this.id = user.uid;
          let token = await user.getIdToken();
          let response = await this.backendLogInWithToken(token);
          this.token = response.token;
          this.onSignIn$.next(true);
          this.isAuthenticated$.next(true);
        }
      });

    this.isAuthenticated$
      .subscribe(async (val) => {
        if (val) {
          const auth = this.auth.auth;
          let sessionToken = await auth.currentUser.getIdToken();
          let snap = await this.getUser(this.id);
          this.user = snap.data();
          this.medxatURL = `https://medxat-app-europa.web.app/?minerva=true&userEmail=${encodeURIComponent(this.user.profile.email)}&withToken=true`;
          let result: any = await this.comm.getUserToken({ token: sessionToken, egea: true, uid: this.id });
          this.medxatToken = result.token;
          this.medxatToken$.next(this.medxatToken);

        }
      });
  }

  getUser(uid): Promise<any> {
    return this.firestore.firestore
      .collection('users')
      .doc(uid)
      .get()
  }

  getUser$(): any {
    return this.firestore
      .collection('users')
      .doc(this.id)
      .snapshotChanges();
  }

  updateProfile(user) {
    return this.firestore.firestore
      .collection('users')
      .doc(this.id)
      .set({
        profile: {
          ...user.profile
        }
      }, { merge: true });
  }

  async backendLogInWithToken(token) {
    return this.comm.loginUserToken({ token });
  }

  async logIn(email, password) {
    const auth = this.auth.auth;
    try {
      let result = await auth.signInWithEmailAndPassword(email, password);
      this.id = result.user.uid;
      this.isAuthenticated$.next(true);
    } catch (e) {
      console.log("ERROR ON sINGIN FIREBASE", e)
    }
  }

  async logout() {
    await this.auth.auth.signOut();
    this.token = null;
    this.medxatToken = null;
    this.medxatToken$.next(this.medxatToken);
    this.id = null;
    this.user = null;
    this.isAuthenticated$.next(false);
    this.onSignOut$.next(true);
    await this.router.navigateByUrl('home');
  }

  async requestResetPassword(email) {
    const md5 = new Md5();
    let encEmail = md5.appendStr(email.toLowerCase()).end().toString();
    let snap = await this.firestore.firestore
      .collection('emailAddr')
      .doc(encEmail)
      .get();

    if (snap.exists) {
      return this.firestore.firestore
        .collection('emailAddr')
        .doc(encEmail)
        .collection('psw_requests')
        .add({ email });
    } else{
      return Promise.resolve("no-exists");
    }
  }
}
