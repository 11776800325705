import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  public baseEndpoint = environment.API_ENDPOINT;
  public medxatBase = environment.MEDXAT_API_ENDPOINT;

  constructor(
    public http: HttpClient,
    public platform: Platform
  ) { }



  loginUserToken(params): Promise<any> {
    const postParams = {
      ...params
    };
    const headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');
    headers.append("Accept", 'application/json');
    return this.http.post(this.medxatBase + '/login', postParams, { headers }).toPromise();
  }

  loginUser(params): Promise<any> {
    const postParams = {
      ...params
    };
    const headers = new HttpHeaders();
    // headers.append("Content-Type", 'application/json');
    // headers.append("Accept", 'application/json');
    return this.http.post(this.baseEndpoint + '/login', postParams, { headers }).toPromise();
  }

  registerUser(params): Promise<any> {
    const postParams = {
      ...params
    };

    const headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');
    headers.set("Accept", 'application/json');

    return this.http.post(this.baseEndpoint + '/register', postParams, { headers }).toPromise();
  }

  realizarPago(url, params): Promise<any> {

    const form = document.createElement('form');
    form.method = 'post';
    form.action = url;
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        // hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
    return Promise.resolve();
  }

  nuevaConsulta(token, params) {
    const postParams = {
      ...params
    };
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }
    return this.http.put(this.baseEndpoint + '/patient/question', postParams, header).toPromise();
  }

  redsysData(token, params) {
    const postParams = {
      product_id: "5e6f65114d73715efcb4d409",
      ...params
    };
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }
    return this.http.post(this.baseEndpoint + '/patient/pay', postParams, header).toPromise();
  }

  getUserToken(params) {
    const postParams = {
      ...params
    };

    const headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');
    headers.append("Accept", 'application/json');
    return this.http.post(environment.MEDXAT_TOKEN_ENDPOINT, postParams, { headers }).toPromise();
  }


  getPrice(params = {}) {
    let product_id = "5e6f65114d73715efcb4d409";
    return this.http.get(this.baseEndpoint + `/patient/products/${product_id}`, params).toPromise();
  }
}
